<template>
  <div class="app-mall-list container">
    <div class="content v padding-20-0" v-loading="loading">
      <template v-if="data && data.length">
        <div class="h wrap">
          <goods-item v-for="g in data" :key="g.id" :goods="g" size="200px" />
        </div>
        <el-pagination class="sa-c" :total="total" :page-size="size" :current-page="page + 1" style="margin: 20px 0;" layout="total, prev, pager, next" @size-change="sizeChange" @current-change="pageChange" />
      </template>
      <div class="fc-g ta-c" v-else>没有找到符合要求的商品哦</div>
    </div>
  </div>
</template>

<script>
import GoodsItem from "../assembly/goods-item";
import initData from "@/mixins/initData";

export default {
  components: { GoodsItem },
  mixins: [initData],
  props: {
    supplier: String | Number,
  },
  watch: {
    supplier: "init",
  },
  data() {
    return {
      size: 24,
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/mall/goods";
      if (this.$route.query.b) {
        this.params.brandId = this.$route.query.b;
      } else {
        delete this.params.brandId;
      }
      if (this.$route.query.s) {
        this.params.seriesId = this.$route.query.s;
      } else {
        delete this.params.seriesId;
      }
      if (this.$route.query.kw) {
        this.params.name = decodeURIComponent(this.$route.query.kw);
      } else {
        delete this.params.name;
      }
      this.params.sellerId = this.supplier;
      return true;
    },
  },
  activated() {
    this.init();
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.init();
  },
};
</script>